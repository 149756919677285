import React from 'react'
import { graphql } from 'gatsby'
import NotFoundPage from '../pages/404'

export const ContactBannerSettingsTemplate = ({ id }) => {
  return <NotFoundPage />
}

export default ({ data }) => {
  const { markdownRemark: contactBannerSettings } = data

  return <ContactBannerSettingsTemplate id={contactBannerSettings.id} />
}

export const ContactBannerSettingsQuery = graphql`
  query ContactBannerSettings($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        isContactBannerEnabled
        phoneHeading
        phoneNumber
        phoneOpeningHours
        phoneLink
        phoneLinkText
        chatHeading
        chatOpeningHours
        chatLink
        chatLinkText
      }
    }
  }
`
